import * as React from 'react';
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImage } from "gatsby-plugin-image";


const ReviewCard = ({node}) => {
    const fm = node.frontmatter
    const hero_image = getImage(fm.hero_image)
    return (
        <article className="card review-card">
            <Link className="text-decoration-none" to={`/reviews/${node.slug}`.replaceAll('_', '-')}>
                <GatsbyImage image={hero_image} className="card-img" alt={fm.hero_image_alt} />
                <div className="card-img-overlay align-bottom">
                    <h3 class="card-title text-white align-bottom">{fm.title}</h3>
                    <p className="text-white">
                        <span className='fs-sm ms-1'>{fm.date}</span>
                    </p>
                </div>
            </Link>
        </article>
    )
}

export default ReviewCard;